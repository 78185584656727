@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/gentelella/src/scss/custom.scss";

@font-face {
  font-family: 'Montserrat-Light';
  src:  url('../../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Montserrat-Light.otf') format('opentype'),
        url('../../fonts/Montserrat-Light.woff') format('woff'),
        url('../../fonts/Montserrat-Light.ttf') format('truetype'),
        url('../../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
.montserrat-light {
    font-family: 'Montserrat-Light';
}

@font-face {
  font-family: 'Montserrat-Regular';
  src:  url('../../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Montserrat-Regular.otf') format('opentype'),
        url('../../fonts/Montserrat-Regular.woff') format('woff'),
        url('../../fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.montserrat-regular {
    font-family: 'Montserrat-Regular';
}

@font-face {
  font-family: 'Montserrat-Medium';
  src:  url('../../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Montserrat-Medium.otf') format('opentype'),
        url('../../fonts/Montserrat-Medium.woff') format('woff'),
        url('../../fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
.montserrat-medium {
    font-family: 'Montserrat-Medium';
}

/* ------------------------ */

body {
    @extend .montserrat-light;
    font-size: 14px;
    line-height: 14px;
    background-color: #000000!important;
}

h1,h2,h3,h4,h5,h6 { 
    @extend .montserrat-regular;
    margin: 0 0 30px 0;
}

strong {
    @extend .montserrat-regular;
}

p {
    @extend .montserrat-light;
    margin: 0 0 15px 0;
    font-size: 14px;
    line-height: 14px;
}

.doc-bold {
    @extend .montserrat-medium
 }

.fa-btn {
    margin-right: 6px;
}

hr {
    border-top: 1px solid #000;
}

.menu_section {
	ul {
		padding-left:0px;
	}
}

body#login-layout {
	background-color: #fff;
}

select[multiple] {
	width: 100%;
    min-height: 200px;
}

.bold {
    font-weight: bold;
}

.no-bold {
    font-weight: normal;
}

.no-bold label {
    font-weight: normal;
}

.pb-col-1, .pb-col-2 {
    float: left;
    width: 50%;
}

.btn-default {
	background: #e6e6e6;
}

.delete input[type="submit"]:hover {
    color: #23527c;
}

.navbar.nav_title {
	height: auto;
    display: inline-block;
}

a.site_title {
    height: 130px;
    text-align: center;
    padding-left: 0;
    img {
    	width: 130px;
    }
    img.sm {
    	width: 50px;
    }
}

.project-nav-title {
   pointer-events: none;
   cursor: default;
}

/* font awesome icons*/
.delete-icon:after {
    content: "\f014";
    font-family: FontAwesome;
    padding-left: 3px;
}

.duplicate-icon:after {
    content: "\f0c5";
    font-family: FontAwesome;
    padding-left: 3px;
}

.edit-icon:after {
    content: "\f044";
    font-family: FontAwesome;
    padding-left: 3px;
}

.spec-icon:after {
    content: "\f0c1";
    font-family: FontAwesome;
    padding-left: 3px;
}

.view-icon:after {
    content: "\f06e";
    font-family: FontAwesome;
    padding-left: 3px;
}

.link-icon:after {
	content: "\f08e";
    font-family: FontAwesome;
    padding-left: 3px;
}

/* admin area */
img.user_signature_link {
	padding: 10px;
}

div.current_file {
	padding-top: 10px;
}

a.document_link {
	display: block;
}

/* admin/users */
.container.users_index form {
	background: none;
	border: none;
	padding: 0;
}

.container.users_index td, .container.users_index th {
	padding: 5px 10px;
	width: calc(100% / 7);
}

.container.users_index .search_term {
	margin-bottom: 5px;
	width: 100%;
}

.extra-search-options {
    display: none;
}

.extra-search-options.show {
    display: block;
}

/* admin/documents */
.container.documents_index form {
    border: none;
    background: none;
	padding: 0;    
}

.container.documents_index td, .container.documents_index th {
	padding: 5px 10px;
	width: calc(100% / 2);
}

.container.documents_index .search_term {
	margin-bottom: 5px;
	width: 100%;
}

/* admin/customers */
.container.customers_index form {
    border: none;
    background: none;
	padding: 0;    
}

.container.customers_index td, .container.customers_index th {
	padding: 5px 10px;
}

.container.customers_index .search_term {
	margin-bottom: 5px;
	width: 100%;
}

a.image_modal {
    cursor: pointer;
}

#image_modal img {
    max-width: 100%;
}

.modal-body.preview .col-1 {
    width: 25%;
    img {
        max-width: 100%;
    }
}

.paragraph {
    position: relative;
    padding-bottom: 15px;
    clear: both;
}

.col-1, .col-2 {
    float: left;
    display: inline-block;
}

.col-1 {
    margin-right: 10px;
}

.doc-heading .col-1 {
    float: left;
    width: 50%;
    margin: 0;
}

.doc-heading .col-2 {
    float: right;
    width: 50%;
    margin: 0;
    text-align: right;
}

.front-cover-images-inline {
    float: left;
    width: 50%;
}

.form-group.custom {
    margin-top: 10px;
}

.cover-image {
    width: auto;
    max-height: 280px;
}

.default-cover-image, .front-cover-image {
    max-width: 275px;
}

input[name="default_cover_image"] {
    margin-right: 15px;
}

.paragraph-image {
    max-width: 200px;
}

body.panel-default {
    border-color: transparent;
}

.doc-header {
    color: #fff;
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 180px 0 0;
    background-color: #F7B126;

    .doc-header__logo {
        position: absolute;
        top: 40px;
        left: 60px;   
        height: 79px;
    }

    .doc-header__company-title {
        padding: 0 60px;
        font-size: 55px;
        @extend .montserrat-regular;
    }

    .doc-header__doc-title {
        padding: 30px 60px;
        p {
            margin: 0;
            // font-family: 'Times New Roman';
        }
        span {
            display: inline-block;
            margin-right: 5px;
            // display: block;
            color: #000;
            &.doc-bold {
                // font-family: 'Times New Roman';
            }
        }
    }

}

.page-header {
    border: 0;
    margin-bottom: 20px;
}

.doc-content {
    padding: 0 60px;
    color: #1A1919;

    span {
        &.doc-bold {
            // font-family: 'Times New Roman';
            // font-weight: bold;
        }
    }
    .doc-heading {
        width: 100%;
        position: relative;
        float: left;
        display: inline-block;
        margin: 30px 0 50px;
        padding: 30px 0 0 0;
        font-size: 26px;
        color: #1A1919;

        &:after {
            width: 20%;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            padding: 15px 0;
            content: "";
            border-top: 1px solid #F7B126;
        }
    }

    .doc-subheading {
        font-size: 16px;
        color: #F7B126;
        margin-bottom: 15px;
    }
}

.container.customers_index .doc-table {
    td {
        padding: 0 0 5px 0;
    }
}

.project-details {
    color: #fff;
    background-color: #2A3F54;
    padding: 10px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.project-details-row {
    position: relative;
    display: inline-block;
    width: 100%;
}

.form-check.indent {
    margin-left: 20px;
}

input#paragraph_subtitle {
    width: 100%;
}

.doc-cover-letter__logo {
    width: 30%;
    // height: 100%;
    text-align: center;
    display: inline-block;
}

.doc-cover-letter__merchant-logo {
    width: 30%;
    padding: 10px;
    text-align: center;
    // height: 100%;
    display: inline-block;
}

.doc-cover-letter__contractor-logo {
    width: 30%;
    padding: 10px;
    text-align: center;
    // height: 100%;
    display: inline-block;
}

.doc-header {
  color: #1A1919;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 40px 0 0;
  }
  .doc-header .doc-header__company-title {
    padding: 0 60px;
    font-size: 25px; }
  .doc-header .doc-header__doc-title {
    padding: 15px 60px 30px; }
    .doc-header .doc-header__doc-title p {
      margin: 0; }
    .doc-header .doc-header__doc-title span {
      display: inline-block;
      margin-right: 5px;
      color: #000;
      font-size: 20px;
    }

.doc-header-footer {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  padding-top: 100px;
}
.doc-header-footer__text {
  position: relative;
  display: inline-block;
  float: left;
  width: 70%;
  padding: 56px 30px 0; 
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.doc-header-footer__logo {
  position: relative;
  display: inline-block;
  float: right;
  width: 30%;
  text-align: right;
}
.doc-header-footer__logo img{
  max-width: 100px;
  margin-right: 30px;
}

.page-header {
  border: 0;
  margin-bottom: 20px; }

.doc-content {
  padding: 0 60px;
  color: #1A1919; }
  .doc-content .doc-heading {
    float: left;
    display: block;
    width: 100%;
    margin: 30px 0 25px;
    font-size: 26px;
  }
  .doc-content .doc-subheading {
    font-size: 16px;
    color: #F7B126;
    margin-bottom: 15px; }

/*  ==========================================================================
    Document cover letter
    ========================================================================== */
    
    .doc-header-content {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        padding-top: 0;
        page-break-inside: avoid;

        table {
            width: 100%!important;
            margin: 0;
            padding: 0;
            border: 0;
    
            tr {
                padding: 0;

                &.split-half {

                    td {
                        width: 50%!important;
                    }
                }
    
                td {
                    padding: 0 0 15px 0!important;

                    img.main-logo {
                        width: 100px;
                        height: auto;
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .doc-header-footer {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        padding-top: 0;
        page-break-inside: avoid;

        table {
            width: 100%;
            margin: 0;
            padding: 0;
            border: 0;

            tr {
                padding: 0;

                &:first-child {

                    td {
                        width: 33.3%;
                    }
                }

                td {
                    padding: 0 0 15px 0!important;
                    font-size: 12px;
                    line-height: 18px;

                    a {
                        text-decoration: none;
                        font-family: 'Montserrat-Medium';
                        color: #1A1919;
                    }

                    img {
                        width: 215px!important;
                        max-width: 215px!important;
                        height: 44px!important;
                        position: relative;
                        float: left;
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
        
    .doc-header-footer .column-one, .doc-header-footer .column-two, .doc-header-footer .column-three, .doc-header-footer .column-full {
        position: relative;
        float: left;
        display: inline-block;
        margin: 0 0 30px 0;
        font-size: 13px;
        line-height: 18px;
    
        a {
            text-decoration: none;
            font-family: 'Montserrat-Medium';
            color: #1A1919;
        }
    }
    
    .doc-header-footer .column-one, .doc-header-footer .column-two, .doc-header-footer .column-three, .doc-header-footer .column-full {
        box-sizing: border-box;

        p {
            margin: 0;
            padding: 0;
            font-size: 12px;
            line-height: 18px;
        }

        img {
            max-width: auto;
            height: auto;
        }
    }
    
    .doc-header-footer .column-one {
        padding-right: 30px;
    }
    
    .doc-header-footer .column-one {
        width: 31%;
        box-sizing: border-box;
    }
    
    .doc-header-footer .column-two {
        width: 35%;
        box-sizing: border-box;
    }
    
    .doc-header-footer .column-three {
        width: 34%;
        text-align: right;
    }
    
    .doc-header-footer .column-full {
        width: 100%;
    }

/*  ==========================================================================
    Document cover page
    ========================================================================== */

    .doc-cover-page {
        max-width: 100%;
        height: 920px;
        background-color: #febc3a;
        background-size: cover;
    }

    .doc-wrapper {
        
        .doc-cover-page {
            max-height: auto;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            overflow: hidden;
            margin: 0;
            padding: 0;
            background-color: #febc3a;
            background-size: cover;
        }
    }

    .doc-cover-page .doc-cover-page__logo {
        display: block;
        width: 100%;
    }

    .doc-cover-page .doc-cover-page__logo img {
        max-width: 350px;
        padding-left: 20px;
        padding-top: 20px;
    }

    .doc-cover-page .doc-cover-page__info {
        width: 100%;
        display: block;
        padding: 0 60px;
        box-sizing: border-box;
    }

    .doc-cover-page .doc-cover-page__heading {
        width: 100%;
        display: block;
        padding: 280px 0 15px 0;
        font-size: 40px;
        line-height: 42px;
        font-family: 'Montserrat-Medium';
        color: #FFF;
    }

    .doc-cover-page .doc-cover-page__subheading {
        width: 100%;
        display: block;
        font-family: 'Montserrat-Medium';
        font-size: 18px;
        color: #fff;
        line-height: 25px;
    }

/*  ==========================================================================
    paragraph icons styling
    ========================================================================== */

    .paragraph-icons {
        width: 100%;
        position: relative;
        margin: 0 0 15px 0;
    }

    .paragraph-icons .paragraph-icons__header {
        width: 100%;
        position: relative;
        margin: 0 0 30px 0;
    }

    .paragraph-icons .paragraph-icons__header h3 {
        width: 100%;
        position: relative;
        margin: 0;
        padding: 0;
    }

    .paragraph-icons .paragraph-icons__row {
        width: 100%;
        position: relative;
    }

    .paragraph-icons .paragraph-icons__column {
        width: 25%;
        position: relative;
        float: left;
        margin: 0;
        padding: 0 15px 0 0;
        box-sizing: border-box;
    }

    .paragraph-icons .paragraph-icons__column img {
        max-width: 100%;
        height: auto;
        position: relative;
    }

    .paragraph-icons .paragraph-icons__column h4 {
        width: 100%;
        position: relative;
        margin: 15px 0 0 0;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
    }

/*  ==========================================================================
    Document product styling
    ========================================================================== */

    /* Elastadeck */
    .doc-elastadeck .doc-cover-page {
        max-width: 100%;
        height: 945px;
        background: #664986 url('/images/updated_front_cover_images/elastadeck-cover.jpg') top left no-repeat;
    }
    .doc-elastadeck .doc-cover-page .doc-cover-page__subheading {
        color: #a9a1bf;
    }
    .doc-elastadeck .doc-content .doc-heading {
        
        &:after {
            border-color: #664986;
        }
    }

    .doc-elastadeck .doc-content .doc-subheading {
        color: #664986;
    }

    /* Elastadeck */
    .doc-elastaseal .doc-cover-page {
        max-width: 100%;
        height: 945px;
        background: #006e9b url('/images/updated_front_cover_images/elastaseal-cover.jpg') top left no-repeat;
    }
    .doc-elastaseal .doc-cover-page .doc-cover-page__subheading {
        color: #fff;
    }
    .doc-elastaseal .doc-content .doc-heading {

        &:after {
            border-color: #006e9b;
        }
    }

    .doc-elastaseal .doc-content .doc-subheading {
        color: #006e9b;
    }

    /* Tortread */
    .doc-tortread .doc-cover-page {
        max-width: 100%;
        height: 945px;
        background: #3a3b3a url('/images/updated_front_cover_images/tortread-cover.jpg') top left no-repeat;
    }
    .doc-tortread .doc-cover-page .doc-cover-page__subheading {
        color: #fff;
    }
    .doc-tortread .doc-content .doc-heading {
        color: #3a3b3a;

        &:after {
            border-color: #3a3b3a;
        }
    }

    .doc-tortread .doc-content .doc-subheading {
        color: #3a3b3a;
    }

    /* Torrex */
    .doc-torrex .doc-cover-page {
        max-width: 100%;
        height: 945px;
        background: #ab2d45 url('/images/updated_front_cover_images/torrex-cover.jpg') top left no-repeat;
    }
    .doc-torrex .doc-cover-page .doc-cover-page__subheading {
        color: #000;
    }
    .doc-torrex .doc-content .doc-heading {

        &:after {
            border-color: #ab2d45;
        }
    }

    .doc-torrex .doc-content .doc-subheading {
        color: #ab2d45;
    }

    /* Unicover */
    .doc-unicover .doc-cover-page {
        max-width: 100%;
        height: 945px;
        background: #66804a url('/images/updated_front_cover_images/unicover-cover.jpg') top left no-repeat;
    }
    .doc-unicover .doc-cover-page .doc-cover-page__subheading {
        color: #000;
    }
    .doc-unicover .doc-content .doc-heading {

        &:after {
            border-color: #66804a;
        }
    }
    .doc-unicover .doc-content .doc-subheading {
        color: #66804a;
    }

    // STYLE OVERRIDES FOR RESTEC 

    $restec_primary: #80ba26;
    $restec_grey: #bebebe;

    body#login-layout {
        background: #ffffff!important;
        background-color: #ffffff!important;
    }

    .alert-success {
        background-color: $restec_primary!important;
        background: $restec_primary!important;
    }

    input.logic-item {
        accent-color: $restec_primary;
    }

    .left_col, .nav_title {
        background: #000000;
    }

    .nav.side-menu {
        li.current-page, li.active {
            border-right: 5px solid $restec_primary;
        }
        li.active > a {
            background: #000000;
        }
    }

    .btn-default {
        background: $restec_primary;
        color: #ffffff;
        &:hover {
            background: $restec_primary;
            color: #ffffff;
        }
    }

    .table > tbody > tr.info > th {
        background-color: #000000;
        color: $restec_grey;
        a {
            color: $restec_grey;
        }
    }

    .nav-md ul.nav.child_menu li:before {
        background: $restec_primary;
    }

    .nav-md ul.nav.child_menu li:after {
        border-left: 1px solid $restec_primary;
    }

    .profile_info h5 {
        color: $restec_grey;
    }

    tr.info {
        &:hover {
            background-color: #000000!important;
            background: #000000!important;
            th {
                background-color: #000000!important;
                background: #000000!important;
            }
        }
    }

    a#menu_toggle i {
        color: $restec_primary;
    }